import React from "react"
import styled from "styled-components"
import euroIconSrc from "../../images/euro-icon.svg";
import algorithmIconSrc from "../../images/algorithm-icon.svg";
import sectorIconSrc from "../../images/sector-icon.svg";
import media, { Mobile, TabletAndDesktop } from "../../shared/mediaQuery"

const KeyPointsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    color: ${props => props.theme.colors.emerald};
    font-size: ${props => props.theme.fontSizes.size04};
    font-weight: bold;
    align-self: center;
    margin-bottom: ${props => props.theme.margins.size06};
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    
    ${media.phone`
        flex-direction: column;
    `}
`

const KeyPointItem = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size06};
    `}
`

const KeyPointTitle = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.grey65};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: bold;
    margin-top: ${props => props.theme.margins.size04};
    margin-bottom: ${props => props.theme.margins.size01};
`

const Description = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.grey65};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: 500;
`

const Illustration = styled.img`
    margin-top: ${props => props.marginTop};
`;

const OptimizerKeyPoints = () => {
    return (
        <KeyPointsContainer>
            <Title>Financieel onder controle</Title>

            <Container>
                <KeyPointItem>
                    <Mobile>
                        <Illustration height={60} src={euroIconSrc} alt={'laagste tarieven'} />
                    </Mobile>
                    <TabletAndDesktop>
                        <Illustration marginTop='19px' height={60} src={euroIconSrc} alt={'laagste tarieven'} />
                    </TabletAndDesktop>
                    <KeyPointTitle>Allerlaagste tarieven</KeyPointTitle>
                    <Description>Poqqy is volledig geautomatiseerd,<br/> hierdoor hanteren we de<br/> allerlaagste tarieven</Description>
                </KeyPointItem>

                <KeyPointItem>
                    <Mobile>
                        <Illustration height={70} src={algorithmIconSrc} alt={'algoritme'} />
                    </Mobile>
                    <TabletAndDesktop>
                        <Illustration height={80} src={algorithmIconSrc} alt={'algoritme'} />
                    </TabletAndDesktop>
                    <KeyPointTitle>De slimste algoritmes</KeyPointTitle>
                    <Description>Onze algoritmes bepalen<br/>
                        het ideale portfolio<br/>
                        voor jou</Description>
                </KeyPointItem>

                <KeyPointItem>
                    <Mobile>
                        <Illustration height={60} src={sectorIconSrc} alt={'bedrijven'} />
                    </Mobile>
                    <TabletAndDesktop>
                        <Illustration marginTop='18px' height={60} src={sectorIconSrc} alt={'bedrijven'} />
                    </TabletAndDesktop>
                    <KeyPointTitle>Volledig gepersonaliseerd</KeyPointTitle>
                    <Description>Gepersonaliseerd beleggen hoeft niet<br/>
                        moeilijk te zijn, wij helpen jou <br/>
                        doorheen het hele proces</Description>
                </KeyPointItem>
            </Container>
        </KeyPointsContainer>

    )
}

export default OptimizerKeyPoints
