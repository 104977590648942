import React from "react"
import styled from "styled-components"
import media from "../../shared/mediaQuery"
import optimizerScreenshotSrc from "../../images/optimizer-screenshot.png"
import Button from "../../shared/components/buttons/button"
import ReactGA from "react-ga"
import Header from "../../shared/components/header"

const HeroContainer = styled.header`
  position: relative;
  height: 80vh;
  background-size: cover;
  background-image: url(/images/poqqy-header.svg);
  
  ${media.phone`
    height: 50vh;
  `}
  
  ${media.desktop`
    height: 50vh;
  `}
`

const Container = styled.div`
  height: calc(80vh - 470px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 84px ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05};
  
   ${media.tablet`
      height: 100%;
      padding: 0 ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05}
  `};
   
   @media (max-height: 750px) {
      height: 100%;
      justify-content: center;
      padding: 0 ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05};
   }
   
   ${media.phone`
      height: 100%;
      justify-content: center;
      padding: ${props => props.theme.margins.size02} ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05};
  `};
`

const SloganAndDescriptionContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  ${media.phone`
      margin-bottom: 0;
  `};
`

const Slogan = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size06};
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: ${props => props.theme.margins.size03};
  
  ${media.phone`
    font-size: ${props => props.theme.fontSizes.size05};
  `};
`

const Description = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fontSizes.size04};
  font-weight: 500;
  width: 100%;
  margin-bottom: ${props => props.theme.margins.size04};
  
  ${media.phone`
      width: 100%;
      font-size: ${props => props.theme.fontSizes.size03};
  `}
`

const OptimizerImageContainer = styled.div`
  display: flex;
  justify-content: center;
`

const OptimizerImage = styled.img`
  position: absolute;
  bottom: -130px;
  height: 500px;
   
  ${media.phone`
      display: none;
  `};
  
   ${media.desktop`
      display: none;
  `};
   
   @media (max-height: 750px) {
      display: none;
   }
`

const MainHeader = () => {
    const navigateToOptimizer = () => {
        ReactGA.event({
            category: "Optimizer",
            action: "User clicked link to optimizer",
        })
        window.open("https://optimizer.poqqy.com", "_self")
    }

    return (
        <HeroContainer>
            <div
                style={{
                    height: "100%",
                    margin: `0 auto`,
                }}>
                <Header/>

                <Container>
                    <SloganAndDescriptionContainer>
                        <Slogan>Select. Invest. Impact.</Slogan>
                        <Description>
                            Met poqqy stel je binnen een paar klikken jouw ideale duurzame portefeuille samen. <br/>
                            Geef aan wat jij belangrijk vindt en ons algoritme doet de rest!
                        </Description>
                        <Button onClick={navigateToOptimizer}>Aan de slag!</Button>
                    </SloganAndDescriptionContainer>
                </Container>

                <OptimizerImageContainer>
                    <OptimizerImage src={optimizerScreenshotSrc} alt='optimizer-screenshot'/>
                </OptimizerImageContainer>
            </div>
        </HeroContainer>
    )
}

export default MainHeader
