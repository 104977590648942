import React from "react"
import styled from "styled-components"
import Button from "../../shared/components/buttons/button"
import media from "../../shared/mediaQuery"
import ReactGA from "react-ga"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #99e37b, ${props => props.theme.colors.emerald});
    padding: ${props => props.theme.margins.size09} 0;
`

const BannerTitle = styled.div`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.size06};
    font-weight: 500;
    margin-bottom: ${props => props.theme.margins.size04};
    
    ${media.phone`
        font-size: ${props => props.theme.fontSizes.size05};
    `};
`

const BannerDescription = styled.div`
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: 500;
    margin-bottom: ${props => props.theme.margins.size05};
`

const OptimizerBanner = () => {
    const navigateToOptimizer = () => {
        ReactGA.event({
            category: "Optimizer",
            action: "User clicked link to optimizer",
        })
        window.open("https://optimizer.poqqy.com", "_self")
    }

    return (
        <Container>
            <BannerTitle>
                Benieuwd naar jouw ideale portfolio?
            </BannerTitle>
            <BannerDescription>
                Wacht niet langer, bereken deze nu
            </BannerDescription>
            <Button onClick={navigateToOptimizer}>Naar optimizer</Button>
        </Container>
    )
}

export default OptimizerBanner
