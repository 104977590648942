import React from "react"
import Layout from "../components/shared/layout"
import SEO from "../components/shared/seo"
import { theme } from "../shared/theme"
import styled, { ThemeProvider } from "styled-components"
import MainHeader from "../components/optimizer/mainHeader"
import Footer from "../components/shared/footer"
import media from "../shared/mediaQuery"
import OptimizerKeyPoints from "../components/optimizer/optimizerKeyPoints"
import OptimizerOverview from "../components/optimizer/optimizerOverview"
import OptimizerSteps from "../components/optimizer/optimizerSteps"
import OptimizerBanner from "../components/optimizer/optimizerBanner"

const Divider = styled.div`
    margin-bottom: ${props => props.theme.margins.size11};
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size08};
    `}
`

const FirstContainer = styled.div`
    box-sizing: border-box;
    max-width: 960px;
    margin: 0 auto;
    padding: ${props => props.theme.margins.size10} ${props => props.theme.margins.size05} 0 ${props => props.theme.margins.size05};
  
    ${media.phone`
        padding: ${props => props.theme.margins.size08} ${props => props.theme.margins.size06} 0 ${props => props.theme.margins.size06};
        margin: 0;
    `}
`

const LastContainer = styled.div`
    box-sizing: border-box;
    max-width: 960px;
    margin: 0 auto;
    padding: 0 ${props => props.theme.margins.size05} ${props => props.theme.margins.size10} ${props => props.theme.margins.size05};
  
    ${media.phone`
        padding: ${props => props.theme.margins.size08} ${props => props.theme.margins.size06};
        margin: 0;
    `}
`

const ContentContainer = styled.div`
  box-sizing: border-box;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 ${props => props.theme.margins.size08};
  
  ${media.phone`
    padding: 0 ${props => props.theme.margins.size06};
    margin: 0;
  `}
`

const IndexPage = () => (
    <ThemeProvider theme={theme}>
        <React.Fragment>
            <Layout header={<MainHeader/>}>
                <SEO title="Select. Invest. Impact." keywords={[`poqqy`, `beleggen`, `fondsen`, `duurzaam`, `beste`]}/>
                <FirstContainer>
                    <OptimizerKeyPoints/>
                </FirstContainer>

                <ContentContainer>
                    <Divider/>
                    <OptimizerOverview/>
                    <Divider/>
                </ContentContainer>

                <LastContainer>
                    <OptimizerSteps/>
                </LastContainer>
            </Layout>
            <OptimizerBanner/>
            <Footer/>
        </React.Fragment>
    </ThemeProvider>
)

export default IndexPage
