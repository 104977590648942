import React from "react"
import styled from "styled-components"
import flaskIconSrc from "../../images/flask-icon.svg"
import graphIconSrc from "../../images/graph-icon.svg"
import sectorIconSrc from "../../images/sector-icon.svg"
import media, { Mobile, TabletAndDesktop } from "../../shared/mediaQuery"
import Card from "../../shared/components/card"

const KeyPointsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    color: ${props => props.theme.colors.emerald};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: bold;
    align-self: center;
    margin-bottom: ${props => props.theme.margins.size06};
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    
    ${media.phone`
        flex-direction: column;
    `}
`

const CardContainer = styled(props => <Card {...props} />)`
    flex: 1;
    margin-right: ${props => !props.last && props.theme.margins.size06};
    
     ${media.phone`
        margin-bottom: ${props => props.theme.margins.size06};
        margin-right: 0;
    `}
`

const KeyPointItem = styled.div`
    display: flex;
    flex-direction: column;
`

const KeyPointTitle = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.grey65};
    font-size: ${props => props.theme.fontSizes.size02};
    font-weight: bold;
    margin-top: ${props => props.theme.margins.size04};
    margin-bottom: ${props => props.theme.margins.size01};
`

const Description = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.grey65};
    font-size: ${props => props.theme.fontSizes.size03};
    font-weight: 500;
`

const Illustration = styled.img`
    margin-top: ${props => props.marginTop};
`

const OptimizerSteps = () => {
    return (
        <KeyPointsContainer>
            <Title>Snel aan de slag</Title>

            <Container>
                <CardContainer>
                    <KeyPointItem>
                        <Mobile>
                            <Illustration height={60} src={sectorIconSrc} alt={"situatie"}/>
                        </Mobile>
                        <TabletAndDesktop>
                            <Illustration marginTop='11px' height={60} src={sectorIconSrc} alt={"situatie"}/>
                        </TabletAndDesktop>
                        <KeyPointTitle>1. Schets jouw situatie</KeyPointTitle>
                        <Description>Hou ik van risico?<br/>
                            Hoe lang kan ik het geld missen?<br/>
                            Wil ik duurzaam beleggen?</Description>
                    </KeyPointItem>
                </CardContainer>


                <CardContainer>
                    <KeyPointItem>
                        <Mobile>
                            <Illustration height={60} src={flaskIconSrc} alt={"brouwen"}/>
                        </Mobile>
                        <TabletAndDesktop>
                            <Illustration marginTop='11px' height={60} src={flaskIconSrc} alt={"brouwen"}/>
                        </TabletAndDesktop>
                        <KeyPointTitle>2. De optimizer doet zijn werk</KeyPointTitle>
                        <Description>De optimizer neemt jouw situatie
                            en berekent het best presterende
                            portfolio</Description>
                    </KeyPointItem>
                </CardContainer>

                <CardContainer last={true}>
                    <KeyPointItem>
                        <Mobile>
                            <Illustration height={60} src={graphIconSrc} alt={"grafiek"}/>
                        </Mobile>
                        <TabletAndDesktop>
                            <Illustration marginTop='0' height={70} src={graphIconSrc} alt={"grafiek"}/>
                        </TabletAndDesktop>
                        <KeyPointTitle>3. Jouw ideale portfolio</KeyPointTitle>
                        <Description>Jouw ideale portfolio<br/>
                            kan in detail bekeken<br/>
                            worden</Description>
                    </KeyPointItem>
                </CardContainer>
            </Container>
        </KeyPointsContainer>

    )
}

export default OptimizerSteps
