import React from "react"
import styled from "styled-components"
import knowledgeIconSrc from "../../images/knowledge-icon.svg";
import gearsIconSrc from "../../images/gears-icon.svg";
import familyIconSrc from "../../images/family-icon.svg";
import media from "../../shared/mediaQuery"

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const OverviewItem = styled.div`
    display: flex;
    flex-direction: row;
    
    ${media.phone`
        flex-direction: ${props => props.reverse ? 'column-reverse' : 'column'};
    `};
`

const SummaryContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 5;
`

const SummaryTitle = styled.div`
    color: ${props => props.theme.colors.grey50};
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes.size05};
    margin-bottom: ${props => props.theme.margins.size02};
`

const SummaryDescription = styled.div`
    font-size: ${props => props.theme.fontSizes.size03};
    color: ${props => props.theme.colors.grey65};
    width: 90%;
    
    ${media.phone`
        width: 100%;
    `};
`

const TextDivider = styled.div`
    margin-bottom: ${props => props.theme.margins.size01};
`

const ImageContainer = styled.div`
    display: flex;
    flex: 1.5;
    align-items: center;
    justify-content: center;
    
    ${media.phone`
        align-items: center;
        margin-bottom: ${props => props.theme.margins.size06};
    `};
`

const Image = styled.img`
    z-index: 2;
    
    ${media.phone`
        height: 70px;
    `};
`

const ColorfulCircle = styled.div`
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.sulu95};
    z-index: 1;
`

const Divider = styled.div`
    margin-bottom: ${props => props.theme.margins.size11};
    
    ${media.phone`
        margin-bottom: ${props => props.theme.margins.size08};
    `};
`

const OptimizerOverview = () => {
    return (
        <Container>
            <OverviewItem reverse={true}>
                <SummaryContainer>
                    <SummaryTitle>
                        Kennis openstellen
                    </SummaryTitle>
                    <SummaryDescription>
                        Kennis en ervaring met betrekking tot de financiële wereld en
                        algoritmes is gecentreerd bij een kleine groep mensen.
                        <TextDivider/>
                        Toegang tot deze kennis en ervaring was alleen mogelijk tegen
                        hoge tarieven.
                        <TextDivider/>
                        poqqy heeft toegang tot de collectieve kennis en ervaring van algoritmes
                        en stelt deze beschikbaar aan iedereen die op zoek is naar onafhankelijkheid.
                    </SummaryDescription>
                </SummaryContainer>
                <ImageContainer reverse={true}>
                    <Image height={90} src={knowledgeIconSrc} alt='kennis'/>
                    <ColorfulCircle/>
                </ImageContainer>
            </OverviewItem>

            <Divider/>

            <OverviewItem reverse={false}>
                <ImageContainer>
                    <Image height={100} src={gearsIconSrc} alt='optimizer'/>
                    <ColorfulCircle/>
                </ImageContainer>

                <SummaryContainer>
                    <SummaryTitle>
                        De optimizer als oplossing
                    </SummaryTitle>
                    <SummaryDescription>
                        Op basis van jouw voorkeuren wordt ons algoritme aan het werk gezet en
                        geoptimaliseerd.
                        <TextDivider/>
                        Welk risico past goed bij jou? Hoe lang wil je beleggen, etc.?
                        Jouw voorkeuren worden gebruikt als input voor ons algoritme.
                        <TextDivider/>
                        Op basis van historisch rendement en risico minimalisering, stelt de optimizer
                        jouw optimale portfolio samen tegen de laagste tarieven.
                    </SummaryDescription>
                </SummaryContainer>
            </OverviewItem>

            <Divider/>

            <OverviewItem reverse={true}>
                <SummaryContainer>
                    <SummaryTitle>
                        Geschikt voor iedereen
                    </SummaryTitle>
                    <SummaryDescription>
                        Soms horen wij als poqqy, ‘ik weet niet hoe ik moet beleggen’,
                        ‘ik weet niet hoe te beginnen’ of ‘het managen van mijn portfolio
                        kost tijd en moeite’.
                        <TextDivider/>
                        Poqqy lost dit probleem op! We begeleiden je stap voor stap naar
                        jouw ideale portfolio.
                    </SummaryDescription>
                </SummaryContainer>
                <ImageContainer reverse={true}>
                    <Image height={120} src={familyIconSrc} alt='iedereen'/>
                    <ColorfulCircle/>
                </ImageContainer>
            </OverviewItem>
        </Container>
    )
}

export default OptimizerOverview
